import Layout from "@/layout";

export default {
  Layout,
  //  定时任务
  'job': () => import('@/view/timedTasks/job/index.vue'),
  'jobLog': () => import('@/view/timedTasks/jobLog/index.vue'),
  // 站内消息
  'inbox': () => import('@/view/onSiteMessages/inbox/index.vue'),
  'announce': () => import('@/view/onSiteMessages/announce/index.vue'),
  // 开发工具
  'sms': () => import('@/view/developmentTool/sms/index.vue'),
  'doc': () => import('@/view/developmentTool/doc/index.vue'),
  'oss': () => import('@/view/developmentTool/oss/index.vue'),
  'mail': () => import('@/view/developmentTool/mail/index.vue'),
  'dict': () => import('@/view/developmentTool/dict/index.vue'),
  'config': () => import('@/view/developmentTool/config/index.vue'),
  'dataSource': () => import('@/view/developmentTool/dataSource/index.vue'),
  // 系统监控
  'log-auth': () => import('@/view/systemMonitor/log/auth.vue'),
  'redis': () => import('@/view/systemMonitor/redis/index.vue'),
  'log-oper': () => import('@/view/systemMonitor/log/oper.vue'),
  'server': () => import('@/view/systemMonitor/server/index.vue'),
  'online': () => import('@/view/systemMonitor/online/index.vue'),
  // 系统管理
  'dept': () => import('@/view/systemManagement/dept/index.vue'),
  'role': () => import('@/view/systemManagement/role/index.vue'),
  'user': () => import('@/view/systemManagement/user/index.vue'),
  'post': () => import('@/view/systemManagement/post/index.vue'),
  'power': () => import('@/view/systemManagement/power/index.vue'),
  'tenant': () => import('@/view/systemManagement/tenant/index.vue'),
  'contacts': () => import('@/view/systemManagement/contacts/index.vue'),
  'dept-list': () => import('@/view/systemManagement/dept/index.vue'),
  // 
  //政策云
  'examine': () => import('@/view/politicalStrategyCloud/examine/index.vue'),
  'policyList': () => import('@/view/politicalStrategyCloud/policyList/index.vue'),
  'policyLabel': () => import('@/view/politicalStrategyCloud/policyLabel/index.vue'),
  'yqpolicyList': () => import('@/view/politicalStrategyCloud/yqpolicyList/index.vue'),
  'policyPreaching': () => import('@/view/politicalStrategyCloud/policyPreaching/index.vue'),
  'projectManagement': () => import('@/view/politicalStrategyCloud/projectManagement/index.vue'),
  //知产云
  'trademarklist': () => import('@/view/zhichanYun/trademarklist/index.vue'),
  'serviceCategory': () => import('@/view/zhichanYun/serviceCategory/index.vue'),
  'industryClassification': () => import('@/view/zhichanYun/industryClassification/index.vue'),
  'institutionalApplication': () => import('@/view/zhichanYun/institutionalApplication/index.vue'),
  'servicePackageManagement': () => import('@/view/zhichanYun/servicePackageManagement/index.vue'),
  // vip服务
  'vipPriceMana': () => import('@/view/vipService/vipPriceMana/index.vue'),
  'membershipOrders': () => import('@/view/vipService/membershipOrders/index.vue'),
  'customerManaging': () => import('@/view/vipService/customerManaging/index.vue'),
  'packageManagement': () => import('@/view/vipService/packageManagement/index.vue'),
  // 金融云 
  'loanTypes': () => import('@/view/financialCloud/loanTypes/index.vue'),
  'workersMana': () => import('@/view/financialCloud/workersMana/index.vue'),
  'bankManagement': () => import('@/view/financialCloud/bankManagement/index.vue'),
  'inancialProducts': () => import('@/view/financialCloud/inancialProducts/index.vue'),
  // 租户管理
  'accountDetails': () => import('@/view/tenant/accountDetails/index.vue'),
  'rechargepackage': () => import('@/view/tenant/rechargepackage/index.vue'),
  'contractTemplate': () => import('@/view/tenant/contractTemplate/index.vue'),
  'tenantManagement': () => import('@/view/tenant/tenantManagement/index.vue'),
  //  工作空间
  'dashboard-console': () => import('@/view/workspace/console/index.vue'),
  'dashboard-workspace': () => import('@/view/workspace/space/index.vue'),
  // 
  'java': () => import('@/view/java/index.vue'),
  'error-403': () => import('@/view/error/403.vue'),
  'error-404': () => import('@/view/error/404.vue'),
  'error-500': () => import('@/view/error/500.vue'),
  'mainPage': () => import('@/view/mainPage/index.vue'),
  'conversion': () => import('@/view/conversion/index.vue'),
  'result-success': () => import('@/view/result/success.vue'),
  'result-failure': () => import('@/view/result/failure.vue'),
  'bannerList': () => import('@/view/bannerList/list/index.vue'),
  'iterationSystem': () => import('@/view/iterationSystem/index.vue'),
  'membershipService': () => import('@/view/membershipService/index.vue'),
  'monitorSystemPage': () => import('@/view/monitorSystemPage/index.vue'),
  'supplyAndDemandlist': () => import('@/view/supplyAndDemand/list/index.vue'),
  'applicablerecords': () => import('@/view/systemApplicable/applicablerecords/index.vue'),
}
