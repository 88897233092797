import request from "../request";

/** 接口 */
const Api = {
  save: "/api/sys/user/save",
  page: "/api/sys/user/page",
  list: "/api/sys/user/list",
  give: "/api/sys/user/give",
  edit: "/api/sys/user/edit",
  editPassword: "/api/sys/user/password/edit",
  resetPassword: "/api/sys/user/password/reset",
  menu: "/api/sys/user/menu",
  power: "/api/sys/user/power",
  remove: "/api/sys/user/remove",
  removeBatch: "/api/sys/user/removeBatch",
  role: "/api/sys/user/role",
  login: "/api/login",
  logout: "/api/logout",
  profile: "/api/sys/user/profile",
  //政策列表
  policyList: "/api/policy/list",
  searchListPage: "/search/listPage",
  searchAdd: "/search/add",
  searchEdit: "/search/edit",
  searchDel: "/search/remove",
  policySelectList: "/api/policy/selectList",
  projectsSticky: "api/policy/sticky",
  projectsHot: "/api/policy/hot",
  policyDel: "/api/policy/remove",
  policyAdd: "/api/policy/add",
  policyEdit: "/api/policy/edit",
  policyGetCascaderSelect: "/api/policy/getCascaderSelect",
  policyGetGatewaySelect: "/api/policy/getGatewaySelect",
  villageList: "/village/village/villageList",
  getTypeSelect: "/api/policy/getTypeSelect",
  getLabelTree: "/api/policyLabel/getLabelTree",
  policyUpdate: "/api/policy/readedAndUpdate",
  policyReaded: "/api/policy/readed",
  getDetail: "/api/policy/get",
  matchPolicyBackEndAdmin: "/api/policy/matchPolicyBackEndAdmin",
  // 企业申报
  reporhigherPcDeclarationList: "/java/Reporhigher/pcDeclarationList",
  // 政策标签
  policyLabelList: "/api/policyLabel/list",
  upload: process.env.VUE_APP_API_BASE_URL + "/api/sys/oss/upload",
  manage: "/apiOrg/org/owner/manage",
  getMessageNotificaAdd: "/java/MessageNotifica/add",
  getPolicyList: "/java/Policy/list",
  reporhigherList: "/java/Reporhigher/list",
  newPolicyList: "/java/newPolicy/list",
  // 更新管理
  addCreateProject: "/uc/createProject",
  logList: "/uc/logList",
  checkLog: "/uc/checkLog",
  listProject: "/uc/listProject",
  closeProcess: "/uc/closeProcess",
  startProcess: "/uc/startProcess",
  killProcess: "/uc/killProcessForce",
  queryProcessByName: "/uc/queryProcessByName",
  updateJar: "/uc/updateJar",
  uploadJar: "/uc/uploadJar",
  editProject: "/uc/editProject",
  startProject: "/uc/startProject",
  closeProject: "/uc/closeProject",
  listBak: "/uc/listBak",
  revertFromBak: "/uc/revertFromBak",
  operatePage: "/api/operate/page",
  patentPage: "/api/patent/page",
  warPage: "/api/war/page",
  queryList: "/api/war/queryList",
  policyGetDraft: "/api/policy/getDraft",
  policySaveDraft: "/api/policy/saveDraft",
  // 金融云 产品
  productPage: "/api/product/page",
  productAdd: "/api/product/add",
  productEdit: "/api/product/edit",
  productDelete: "/api/product/delete",
  // 贷款类型
  loanTypePage: "/api/loanType/page",
  loanTypeAdd: "/api/loanType/add",
  loanTypeDelete: "/api/loanType/delete",
  projectListByIds: "/api/project/listByIds"

};
// 企业申报

export const reporhigherPcDeclarationListApi = (data) => {
  return request.request({
    url: Api.reporhigherPcDeclarationList,
    data,
    method: "POST",
    // params: data,
    // method: "GET",
  });
};
// 贷款类型
export const loanTypePageApi = (data) => {
  return request.request({
    url: Api.loanTypePage,
    params: data,
    method: "GET",
  });
};
// 热门 列表的
export const searchListPageApi = (data) => {
  return request.request({
    url: Api.searchListPage,
    params: data,
    method: "GET",
  });
};
// 热门 添加
export const searchAddApi = (data) => {
  return request.request({
    url: Api.searchAdd,
    data,
    method: "POST",
  });
};
export const searchEditApi = (data) => {
  return request.request({
    url: Api.searchEdit,
    data,
    method: "POST",
  });
};
export const projectListByIdsApi = (data) => {
  return request.request({
    url: Api.projectListByIds,
    data,
    method: "POST",
  });
};
// 热门 添加
export const searchDelApi = (data) => {
  return request.request({
    url: Api.searchDel,
    params: data,
    method: "Delete",
  });
};
export const loanTypeAddApi = (data) => {
  return request.request({
    url: Api.loanTypeAdd,
    data,
    method: "POST",
  });
};
export const loanTypeDeleteApi = (data) => {
  return request.request({
    url: Api.loanTypeDelete,
    params: data,
    method: "POST",
  });
};
// 金融云
export const productPageApi = (data) => {
  return request.request({
    url: Api.productPage,
    params: data,
    method: "GET",
  });
};
// 金融云 产品 添加
export const productAddApi = (data) => {
  return request.request({
    url: Api.productAdd,
    data,
    method: "POST",
  });
};
// 金融云 产品 编辑
export const productEditApi = (data) => {
  return request.request({
    url: Api.productEdit,
    data,
    method: "POST",
  });
};
// 金融云 产品 删除
export const productDeleteApi = (data) => {
  return request.request({
    url: Api.productDelete,
    params: data,
    method: "GET",
  });
};
//保存草稿 
export const policySaveDraftApi = (data) => {
  return request.request({
    url: Api.policySaveDraft,
    data,
    method: "POST",
  });
};
// 获取草稿
export const policyGetDraftApi = (data) => {
  return request.request({
    url: Api.policyGetDraft,
    params: data,
    method: "GET",
  });
};
//战兴产业父数据
export const getWarPage = (data) => {
  return request.request({
    url: Api.warPage,
    params: data,
    method: "GET",
  });
};
//战兴产业子数据
export const getqQueryList = (data) => {
  return request.request({
    url: Api.queryList,
    params: data,
    method: "GET",
  });
};
//查询专利-运营 列表
export const getPatentPage = (data) => {
  return request.request({
    url: Api.patentPage,
    params: data,
    method: "GET",
  });
};
//查询专利-运营 列表
export const getOperatePage = (data) => {
  return request.request({
    url: Api.operatePage,
    params: data,
    method: "GET",
  });
};
//回撤
export const getRevertFromBak = (data) => {
  return request.request({
    url: Api.revertFromBak,
    data,
    method: "POST",
  });
};
//备份列表
export const getlistBak = (data) => {
  return request.request({
    url: Api.listBak,
    data,
    method: "POST",
  });
};
//上传
export const getuploadJar = (data) => {
  return request.request({
    url: Api.uploadJar,
    data,
    method: "POST",
    timeout: 60000,
  });
};
//启动项目
export const getStartProject = (data) => {
  return request.request({
    url: Api.startProject,
    data,
    method: "POST",
  });
};
//关闭项目
export const getCloseProject = (data) => {
  return request.request({
    url: Api.closeProject,
    data,
    method: "POST",
  });
};
// 更新jar包
export const getUpdateJar = (data) => {
  return request.request({
    url: Api.updateJar,
    data,
    method: "POST",
  });
};
// 编辑
export const getEditProject = (data) => {
  return request.request({
    url: Api.editProject,
    data,
    method: "POST",
  });
};
// 创建项目
export const getAddCreateProject = (data) => {
  return request.request({
    url: Api.addCreateProject,
    data,
    method: "POST",
  });
};
//关闭项目
export const getkillProcess = (data) => {
  return request.request({
    url: Api.killProcess,
    data,
    method: "POST",
  });
};
// 查看详情
export const getqueryProcessByName = (data) => {
  return request.request({
    url: Api.queryProcessByName,
    params: data,
    method: "POST",
  });
};
// 关闭项目
export const getCloseProcess = (data) => {
  return request.request({
    url: Api.closeProcess,
    data,
    method: "POST",
  });
};
// 启动项目
export const getStartProcess = (data) => {
  return request.request({
    url: Api.startProcess,
    data,
    method: "POST",
  });
};
// 日志列表
export const getLogListData = (data) => {
  return request.request({
    url: Api.logList,
    params: data,
    method: "POST",
  });
};
// 查看日志
export const getCheckLog = (data) => {
  return request.request({
    url: Api.checkLog,
    data,
    method: "POST",
  });
};
// 项目列表
export const getListProject = (data) => {
  return request.request({
    url: Api.listProject,
    data,
    method: "POST",
  });
};
export const getNewPolicyList = (data) => {
  return request.request({
    url: Api.newPolicyList,
    params: data,
    method: "GET",
  });
};
export const projectsStickyApi = (data) => {
  return request.request({
    url: Api.projectsSticky,
    params: data,
    method: "POST",
  });
};
export const projectsHotApi = (data) => {
  return request.request({
    url: Api.projectsHot,
    params: data,
    method: "POST",
  });
};
export const getReporhigherList = (data) => {
  return request.request({
    url: Api.reporhigherList,
    params: data,
    method: "GET",
  });
};
//联系企业
export const getPolicyList = (data) => {
  return request.request({
    url: Api.getPolicyList,
    params: data,
    method: "GET",
  });
};
//联系企业
export const getMessageNotificaAdd = (data) => {
  return request.request({
    url: Api.messageNotificaAdd,
    params: data,
    method: "POST",
  });
};
//上传
export const manage = (data) => {
  return request.request({
    url: Api.manage,
    params: data,
    method: "GET",
  });
};
//上传
export const getupload = (data) => {
  return request.request({
    url: Api.upload,
    data,
    method: "POST",
  });
};
// 范围
export const getVillageList = (data) => {
  return request.request({
    url: Api.villageList,
    params: data,
    method: "GET",
  });
};
// 政策 匹配列表
export const policyLabelList = (data) => {
  return request.request({
    url: Api.policyLabelList,
    method: "GET",
  });
};
// 政策 匹配
export const setMatchPolicyBackEndAdmin = (data) => {
  return request.request({
    url: Api.matchPolicyBackEndAdmin,
    data,
    method: "POST",
  });
};
// 政策 详情
export const getDetail = (data) => {
  return request.request({
    url: Api.getDetail,
    params: data,
    method: "POST",
  });
};
// 政策 发布并同步到历史公告
export const setpolicyUpdate = (data) => {
  return request.request({
    url: Api.policyUpdate,
    data,
    method: "POST",
  });
};
// 政策 解读
export const setPolicyReaded = (data) => {
  return request.request({
    url: Api.policyReaded,
    data,
    method: "POST",
  });
};
// 政策 匹配
export const getLabelTree = (data) => {
  return request.request({
    url: Api.getLabelTree,
    data,
    method: "POST",
  });
};
// 政策 类型
export const getTypeSelect = (data) => {
  return request.request({
    url: Api.getTypeSelect,
    data,
    method: "POST",
  });
};
// 政策 列表
export const policyList = (data) => {
  return request.request({
    url: Api.policyList,
    params: data,
    method: "GET",
  });
};
export const policySelectListApi = (data) => {
  return request.request({
    url: Api.policySelectList,
    params: data,
    method: "GET",
  });
};
// 政策 列表 删除
export const policyDel = (data) => {
  return request.request({
    url: Api.policyDel,
    params: data,
    method: "Delete",
  });
};
// 政策 发布 添加
export const policyAdd = (data) => {
  return request.request({
    url: Api.policyAdd,
    data,
    method: "POST",
  });
};
// 政策 发布 添加
export const policyEdit = (data) => {
  return request.request({
    url: Api.policyEdit,
    data,
    method: "POST",
  });
};
// 政策 发布地域下拉框
export const policyGetCascaderSelect = (data) => {
  return request.request({
    url: Api.policyGetCascaderSelect,
    params: data,
    method: "GET",
  });
};
// 政策 厅下下拉框
export const policyGetGatewaySelect = (data) => {
  return request.request({
    url: Api.policyGetGatewaySelect,
    params: data,
    method: "GET",
  });
};
/** 登录 */
export const login = (data) => {
  return request.request({
    url: Api.login,
    params: data,
    method: "POST",
  });
};

/** 注销 */
export const logout = (data) => {
  return request.request({
    url: Api.logout,
    data: data,
    method: "GET",
  });
};

/** 修改密码 */
export const editPassword = (data) => {
  return request.request({
    url: Api.editPassword,
    data: data,
    method: "PUT",
  });
};

/** 重置密码 */
export const resetPassword = (data) => {
  return request.request({
    url: Api.resetPassword,
    params: data,
    method: "PUT",
  });
};

/** 个人信息 */
export const profile = (data) => {
  return request.request({
    url: Api.profile,
    data: data,
    method: "GET",
  });
};

/** 用户菜单 */
export const menu = (data) => {
  return request.request({
    url: Api.menu,
    params: data,
    method: "GET",
  });
};

/** 用户角色 */
export const role = (data) => {
  return request.request({
    url: Api.role,
    params: data,
    method: "GET",
  });
};

/** 用户权限 */
export const power = (data) => {
  return request.request({
    url: Api.power,
    params: data,
    method: "GET",
  });
};

/** 用户列表 */
export const page = (data) => {
  return request.request({
    url: Api.page,
    params: data,
    method: "GET",
  });
};

/** 用户列表 */
export const list = (data) => {
  return request.request({
    url: Api.list,
    params: data,
    method: "GET",
  });
};

/** 用户新增 */
export const save = (data) => {
  return request.request({
    url: Api.save,
    data: data,
    method: "POST",
  });
};

/** 新增用户 */
export const give = (data) => {
  return request.request({
    url: Api.give,
    data: data,
    method: "POST",
  });
};

/** 修改用户 */
export const edit = (data) => {
  return request.request({
    url: Api.edit,
    data: data,
    method: "PUT",
  });
};

/** 用户删除 */
export const remove = (data) => {
  return request.request({
    url: Api.remove,
    params: data,
    method: "DELETE",
  });
};

/** 批量删除 */
export const removeBatch = (data) => {
  return request.request({
    url: Api.removeBatch,
    params: data,
    method: "DELETE",
  });
};
